import React from "react";
import PropTypes from "prop-types";

import "../scss/index.scss";

import TopBar from "./TopBar";
import Seo from "./Seo";

const Layout = ({ children, title }) => {
  return (
    <>
      <Seo title={title} />
      <TopBar />
      <main>{children}</main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  title: "Cin Asia",
};

export default Layout;
