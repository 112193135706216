import React, { useState } from "react";
import {
  AppBar,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";

import "./scss/Topbar.scss";
import { Link } from "gatsby";

export default function TopBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(drawerOpen ? false : true);
  };

  return (
    <div className="top-bar">
      <AppBar position="static" className="app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            <Link to="/">CinAsia</Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        open={drawerOpen}
        onOpen={() => {}}
        onClose={toggleDrawer}
        className="swipeable-drawer"
      >
        <CloseIcon onClick={toggleDrawer} className="close-icon" />
        <List className="list">
          <ListItem>
            <Link to="/" onClick={toggleDrawer}>
              Home
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/directory">Directory</Link>
          </ListItem>
          <ListItem>Articles</ListItem>
          <ListItem>About Us</ListItem>
        </List>
      </SwipeableDrawer>
    </div>
  );
}
